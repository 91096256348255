import { useState, useEffect } from 'react';

function useIsMobile(props) {
  const [isMobile,setIsMobile] = useState(null);

  useEffect(() => {
      if (window && window.matchMedia) {
        updateDimensions()
        window.addEventListener("resize", updateDimensions)
      }
    
      return () => {
        // remove resize for mobile responsiveness on unmount
        if (window && window.matchMedia) {
          window.removeEventListener("resize", updateDimensions)
        }
      }
    })
    
    const updateDimensions = () => {
      if (window && window.matchMedia) {
        setIsMobile(window.matchMedia(`(max-width: ${props}`).matches);
      }
    }

    return isMobile ? isMobile : false;
}

export default useIsMobile